.provision-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  

}
.screen-title{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px !important;
    font-weight: 300;
    text-align: center;
    
}

.screen-titlee{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px !important;
    font-weight: 300;
    text-align: center;
    
}

.loadinggg{
    padding-top: 10px;
}

.homepage-containerr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: white;
    font-family: 'Arial', sans-serif;
}


.homepage-sectionn {
    display: flex ;
    flex-direction: column;
    
    align-items: center;
    background: #504545;
    width: 600px;
    height: 600px;
    border-radius: 30px;
    padding: 10px;
    position: relative;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
    margin-top: -100px;
}

.logo-img{
    padding-top: 3%;
    
}

.provision{
    padding-top: 30px;
}
